<template>
  <div id="bottomLeftChart" style="height: 98%; margin-top: 3%"></div>
</template>

<script>
import { totalDataApi } from "@/api/dataList";

export default {
  data() {
    return {};
  },
  mounted() {
    this.drawPie();
  },
  methods: {
    async drawPie() {
      const res = await totalDataApi(13);
      this.$parent.$parent.$parent.workName = res.data.classify; //子分类nav展示 传给父
      this.$parent.$parent.$parent.chooseNav(
        this.$parent.$parent.$parent.workName[0],
        0
      ); //调用父组件函数（初始默认）
      res.data.classify.forEach((item) => {
        this.$parent.$parent.$parent.describeList.push(item.classifyExplain); //子分类标题展示 传给父
      });
      let category = res.data.category;
      let barData = res.data.barData; //社会参与数
      let rateData = res.data.rateData; //服务人次
      let lineData = res.data.lineData; //活动场次
      let option = {
        title: {
          text: "",
          x: "center",
          y: 0,
          textStyle: {
            color: "#B4B4B4",
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC",
            },
          },
        },
        legend: {
          data: ["总社会参与数", "总活动场次", "总服务人次"],
          textStyle: {
            color: "#B4B4B4",
          },
          top: "0%",
        },
        grid: {
          x: "10%",
          width: "80%",
          y: "4%",
        },
        xAxis: {
          data: category,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0, //使x轴文字显示全
            formatter: function (params) {
              let newParamsName = "";
              const paramsNameNumber = params.length; // 文字总长度
              const provideNumber = 4; //一行显示几个字
              const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                for (let p = 0; p < rowNumber; p++) {
                  const start = p * provideNumber;
                  const end = start + provideNumber;
                  const tempStr =
                    p === rowNumber - 1
                      ? params.substring(start, paramsNameNumber)
                      : params.substring(start, end) + "\n";
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
          },
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },

            axisLabel: {
              formatter: "{value} ",
            },
          },
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisLabel: {
              formatter: "{value} ",
            },
          },
        ],
        series: [
          {
            name: "总服务人次",
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#F02FC2",
              },
            },
            data: rateData,
          },

          {
            name: "总社会参与数",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: "#3EACE5",
              },
            },
            data: barData,
          },

          {
            name: "总活动场次",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: "rgba(156,107,211,0.5)",
              },
            },
            z: -12,
            data: lineData,
          },
        ],
      };
      let myChart = this.$echarts(this.$el);

      myChart.clear();
      myChart.resize();
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
#bottomLeft {
  padding: 1.5rem 1rem;
  //height: 520px;
  width: 93%;
  //min-width: 300px;
  border-radius: 5px;

  .bg-color-black {
    height: 485px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .chart-box {
    margin-top: 1rem;
    width: 170px;
    height: 170px;

    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>